import { Component } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonService } from 'app/_services/common.services';


@Component({
  selector: 'reload',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    DatePipe,
    MaterialModule,
    ReactiveFormsModule,
    DecimalPipe,
    AppFilterPipeModule,
    NgxMatSelectSearchModule,],
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent {
    public companyFilterCtrl: FormControl = new FormControl();
    public searching = false;



    constructor(
        private commonService : CommonService,
    ){}

    openChange(data:any):void{

    }

    selectionChange(data:any):void{

    }


    reload(data):void{
        this.commonService.reLoadPage('Yes');
    }
}

import { CommonModule } from '@angular/common';
import { NgIf, NgFor, NgClass, NgTemplateOutlet, DatePipe, DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'location',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    DatePipe,
    MaterialModule,
    ReactiveFormsModule,
    DecimalPipe,
    AppFilterPipeModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {


    public companyFilterCtrl: FormControl = new FormControl();
    public searching = false;


    openChange(data:any):void{

    }

    selectionChange(data:any):void{

    }

}

/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const project = {
    githubIssues      : {
        overview: {
            'this-week': {
                'new-issues'   : 1,
                'closed-issues': 16,
                'fixed'        : 3,
                'wont-fix'     : 4,
                're-opened'    : 8,
                'needs-triage' : 6,
            },
            'last-week': {
                'new-issues'   : 0,
                'closed-issues': 17,
                'fixed'        : 6,
                'wont-fix'     : 11,
                're-opened'    : 6,
                'needs-triage' : 5,
            },
        },
        labels  : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        series  : {
            'this-week': [
                {
                    name: 'Online',
                    type: 'line',
                    data: [28, 15, 16, 26, 23, 12, 16],
                },
                {
                    name: 'Offline',
                    type: 'column',
                    data: [4, 22, 8, 6, 8, 10, 17],
                },
            ],
            'last-week': [
                {
                    name: 'Online',
                    type: 'line',
                    data: [16, 12, 28, 17, 19, 21, 19],
                },
                {
                    name: 'Offline',
                    type: 'column',
                    data: [8, 8, 10, 12, 7, 11, 15],
                },
            ],
        },
    },
    taskDistribution  : {
        overview: {
            'this-week': {
                'new'      : 0,
                'completed': 4,
            },
            'last-week': {
                'new'      : 0,
                'completed': 4,
            },
        },
        labels  : ['Partition 1', 'Partition 2', 'Partition 3', 'Partition 4'],
        series  : {
            'this-week': [15, 20, 38, 27],
            'last-week': [19, 16, 42, 23],
        },
    },
    schedule          : {
        today   : [
            {
                title   : 'Camera 1',
                time    : 'in 32 minutes',
                location: 'Conference room 1B',
            },
            {
                title: 'Camera 2',
                time : '10:30 AM',
            },
            {
                title: 'Camera 4',
                time : '11:00 AM',
            },
            {
                title: 'Camera 5',
                time : '12:10 PM',
            },
            {
                title   : 'Camera 6',
                time    : '05:30 PM',
                location: 'Magnolia',
            },
            {
                title   : 'Camera 7',
                time    : '07:30 PM',
                location: 'Home',
            },
            {
                title   : 'Camera 7',
                time    : '09:30 PM',
                location: 'Overseer\'s room',
            },
        ],
        tomorrow: [
            {
                title   : 'Camera 8',
                time    : '09:00 AM',
                location: 'Conference room 1A',
            },
            {
                title: 'Camera 9',
                time : '11:00 AM',
            },
            {
                title: 'Camera 10',
                time : '12:10 PM',
            },
            {
                title   : 'Camera 11',
                time    : '03:00 PM',
                location: 'Conference room 2C',
            },
            {
                title: 'Camera 12',
                time : '05:30 PM',
            },
            {
                title   : 'Camera 13',
                time    : '07:30 PM',
                location: 'CEO\'s house',
            },
            {
                title   : 'Camera 14',
                time    : '09:30 PM',
                location: 'CEO\'s Room',
            },
        ],
    },
    budgetDistribution: {
        categories: ['Partition 1', 'Partition 2', 'Partition 3', 'Partition 4'],
        series    : [
            {
                name: 'Storage in GB',
                data: [100, 100, 100, 100],
            },
        ],
    },
    weeklyExpenses    : {
        amount: 17663,
        labels: [
            now.minus({days: 47}).toFormat('dd MMM') + ' - ' + now.minus({days: 40}).toFormat('dd MMM'),
            now.minus({days: 39}).toFormat('dd MMM') + ' - ' + now.minus({days: 32}).toFormat('dd MMM'),
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM'),
        ],
        series: [
            {
                name: 'Missing Configuration',
                data: [22, 15, 5, 10, 8, 2],
            },
        ],
    },
    monthlyExpenses   : {
        amount: 54663,
        labels: [
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM'),
        ],
        series: [
            {
                name: 'Status',
                data: [8, 5, 6, 20],
            },
        ],
    },
    yearlyExpenses    : {
        amount: 648813,
        labels: [
            now.minus({days: 79}).toFormat('dd MMM') + ' - ' + now.minus({days: 72}).toFormat('dd MMM'),
            now.minus({days: 71}).toFormat('dd MMM') + ' - ' + now.minus({days: 64}).toFormat('dd MMM'),
            now.minus({days: 63}).toFormat('dd MMM') + ' - ' + now.minus({days: 56}).toFormat('dd MMM'),
            now.minus({days: 55}).toFormat('dd MMM') + ' - ' + now.minus({days: 48}).toFormat('dd MMM'),
            now.minus({days: 47}).toFormat('dd MMM') + ' - ' + now.minus({days: 40}).toFormat('dd MMM'),
            now.minus({days: 39}).toFormat('dd MMM') + ' - ' + now.minus({days: 32}).toFormat('dd MMM'),
            now.minus({days: 31}).toFormat('dd MMM') + ' - ' + now.minus({days: 24}).toFormat('dd MMM'),
            now.minus({days: 23}).toFormat('dd MMM') + ' - ' + now.minus({days: 16}).toFormat('dd MMM'),
            now.minus({days: 15}).toFormat('dd MMM') + ' - ' + now.minus({days: 8}).toFormat('dd MMM'),
            now.minus({days: 7}).toFormat('dd MMM') + ' - ' + now.toFormat('dd MMM'),
        ],
        series: [
            {
                name: 'Status',
                data: [1,5,9,8,10,11,2],
            },
        ],
    },
    budgetDetails     : {
        columns: ['type', 'total', 'expensesAmount', 'expensesPercentage', 'remainingAmount', 'remainingPercentage'],
        rows   : [
            {
                id                 : 1,
                type               : 'Concept',
                total              : 14880,
                expensesAmount     : 14000,
                expensesPercentage : 94.08,
                remainingAmount    : 880,
                remainingPercentage: 5.92,
            },
            {
                id                 : 2,
                type               : 'Design',
                total              : 21080,
                expensesAmount     : 17240.34,
                expensesPercentage : 81.78,
                remainingAmount    : 3839.66,
                remainingPercentage: 18.22,
            },
            {
                id                 : 3,
                type               : 'Development',
                total              : 34720,
                expensesAmount     : 3518,
                expensesPercentage : 10.13,
                remainingAmount    : 31202,
                remainingPercentage: 89.87,
            },
            {
                id                 : 4,
                type               : 'Extras',
                total              : 18600,
                expensesAmount     : 0,
                expensesPercentage : 0,
                remainingAmount    : 18600,
                remainingPercentage: 100,
            },
            {
                id                 : 5,
                type               : 'Marketing',
                total              : 34720,
                expensesAmount     : 19859.84,
                expensesPercentage : 57.2,
                remainingAmount    : 14860.16,
                remainingPercentage: 42.8,
            },
        ],
    },
    teamMembers       : [
        {
            id    : '2bfa2be5-7688-48d5-b5ac-dc0d9ac97f14',
            avatar: 'assets/images/avatars/female-10.jpg',
            name  : 'Nadia Mcknight',
            email : 'nadiamcknight@mail.com',
            phone : '+1-943-511-2203',
            title : 'Project Director',
        },
        {
            id    : '77a4383b-b5a5-4943-bc46-04c3431d1566',
            avatar: 'assets/images/avatars/male-19.jpg',
            name  : 'Best Blackburn',
            email : 'blackburn.best@beadzza.me',
            phone : '+1-814-498-3701',
            title : 'Senior Developer',
        },
        {
            id    : '8bb0f597-673a-47ca-8c77-2f83219cb9af',
            avatar: 'assets/images/avatars/male-14.jpg',
            name  : 'Duncan Carver',
            email : 'duncancarver@mail.info',
            phone : '+1-968-547-2111',
            title : 'Senior Developer',
        },
        {
            id    : 'c318e31f-1d74-49c5-8dae-2bc5805e2fdb',
            avatar: 'assets/images/avatars/male-01.jpg',
            name  : 'Martin Richards',
            email : 'martinrichards@mail.biz',
            phone : '+1-902-500-2668',
            title : 'Junior Developer',
        },
        {
            id    : '0a8bc517-631a-4a93-aacc-000fa2e8294c',
            avatar: 'assets/images/avatars/female-20.jpg',
            name  : 'Candice Munoz',
            email : 'candicemunoz@mail.co.uk',
            phone : '+1-838-562-2769',
            title : 'Lead Designer',
        },
        {
            id    : 'a4c9945a-757b-40b0-8942-d20e0543cabd',
            avatar: 'assets/images/avatars/female-01.jpg',
            name  : 'Vickie Mosley',
            email : 'vickiemosley@mail.net',
            phone : '+1-939-555-3054',
            title : 'Designer',
        },
        {
            id    : 'b8258ccf-48b5-46a2-9c95-e0bd7580c645',
            avatar: 'assets/images/avatars/female-02.jpg',
            name  : 'Tina Harris',
            email : 'tinaharris@mail.ca',
            phone : '+1-933-464-2431',
            title : 'Designer',
        },
        {
            id    : 'f004ea79-98fc-436c-9ba5-6cfe32fe583d',
            avatar: 'assets/images/avatars/male-02.jpg',
            name  : 'Holt Manning',
            email : 'holtmanning@mail.org',
            phone : '+1-822-531-2600',
            title : 'Marketing Manager',
        },
        {
            id    : '8b69fe2d-d7cc-4a3d-983d-559173e37d37',
            avatar: 'assets/images/avatars/female-03.jpg',
            name  : 'Misty Ramsey',
            email : 'mistyramsey@mail.us',
            phone : '+1-990-457-2106',
            title : 'Consultant',
        },
    ],
    newVsReturning     : {
        uniqueVisitors: 46085,
        series        : [80, 20],
        labels        : [
            'New',
            'Returning',
        ],
    },
    storagePieChart : {
        uniqueVisitors: 46085,
        series        : [80, 20],
        labels        : [
            'New',
            'Returning',
        ],
    }
};

import {EventEmitter, Injectable} from '@angular/core';
import { List, values } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { MyToastrService } from './toastr.service';
import { BaseRequestService } from './base.service';

@Injectable({ providedIn: 'root' })
export class CommonService {
    Objectkeys = Object.keys;
    public selectedTagChange = new EventEmitter();
    refreshed: Subject<string> = new Subject<string>();
    networkDeviceReloadChange: Subject<any> = new Subject<any>();
    addCompanyChange: Subject<any> = new Subject<any>();
    addSiteChange: Subject<any> = new Subject<any>();
    addChange: Subject<boolean> = new Subject<boolean>();
    refreshtime: Subject<Number> = new Subject<Number>();
    addText: Subject<string> = new Subject<string>();
    createclick: Subject<object> = new Subject<object>();
    tableChange: Subject<object> = new Subject<object>();
    currenttab: Subject<object> = new Subject<object>();
    currentdash: Subject<string> = new Subject<string>();
    currentalerts: Subject<string> = new Subject<string>();
    downloadtrigger: Subject<string> = new Subject<string>();
    reload: Subject<any> = new Subject<any>();
    public nvrFilterCal = new EventEmitter();
    columns: Subject<string> = new Subject<any>();
     public reloadServerSideTable = new EventEmitter();
    searchdata: Subject<object> = new Subject<object>();
    public accessURLs: any = [];
    baseurl: any = `https://iocldev.mydigitalpetro.com`;
    tableFilterCalBack: Subject<object> = new Subject<object>();
    currentScope: any;
    public selectedTableRows: any = {};
    public selectedTableName: any = '';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    subs: Subscription;
    rowSub: Subscription;
    selectedRows = new EventEmitter();
    constructor(private toast: MyToastrService) {};
    tableTotalCount: any = {};

    isPrivateIP(ip: string): boolean {
        const parts = ip.split('.');
        return (
            parts[0] === '10' ||
            (parts[0] === '172' &&
                parseInt(parts[1], 10) >= 16 &&
                parseInt(parts[1], 10) <= 31) ||
            (parts[0] === '192' && parts[1] === '168')
        );
    }

    isZeroOctetIP(ip: string): boolean {
        const parts = ip.split('.');
        if (parts.length !== 4) {
            return true;
        }
        return parts[3] === '0';
    }

    isUuid(uuid: string): boolean {
        if (uuid) {
            // @ts-ignore
            return (
                uuid.match(
                    /^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/
                )?.length > 0
            );
        } else {
            return false;
        }
    }

    nl2br(str: string): string {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    compare(a: number | string, b: number | string, isAsc: boolean): any {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    buildQuery(data: { [x: string]: any }): any {
        const baseQuery = { query: { bool: { must: [] } } };
        this.Objectkeys(data).forEach((obj) => {
            const tmpObj = { match: {} };
            // @ts-ignore
            tmpObj.match[obj] = data[obj];
            // @ts-ignore
            baseQuery.query.bool.must.push(tmpObj);
        });
        return baseQuery;
    }
    onlyUnique(value: any, index: any, self: string | any[]): boolean {
        return self.indexOf(value) === index;
    }
    reloadDevice(deviceId: any): void {
        this.networkDeviceReloadChange.next(deviceId);
    }

    percentCalculator(x: number, y: number): number {
        return Math.floor((y / x) * 100);
    }

    copyClip(cmd: string): void {
        const el = document.createElement('textarea');
        el.value = cmd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.toast.sToast('success', 'Copied to clipboard');
    }

    addCompany(name: any): void {
        this.addCompanyChange.next(name);
    }
    addSite(ele: any, name: any): void {
        ele.action = name.action;
        this.addSiteChange.next(ele);
    }

    add(ele: any, name: any): void {
        // ele.action = name.action;
        this.addChange.next(ele);
        this.addText.next(name);
    }

    refreshtext(interval: any): void {
        this.refreshtime.next(interval);
    }

    create(data): void {
        this.createclick.next(data);
    }

    tabledata(data): void {
        this.tableChange.next(data);
    }

    current(data): void {
        this.currenttab.next(data);
    }

    dash(data): void {
        this.currentdash.next(data);
    }

    alertss(data): void {
        this.currentalerts.next(data);
    }

    triggerfoirdownload(data): void {
        this.downloadtrigger.next(data);
    }

    columnsearch(data): void {
        this.columns.next(data);
    }

    searchfiler(data): void {
        this.searchdata.next(data);
    }

    refresh(data): void {
        this.refreshed.next(data);
    }

    async sortFn(arr: any, key?: string, order?: any): Promise<any> {
        if (key) {
            return await arr.sort((a, b) => {
                const c = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
                const d = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
                if (!order || (order && order === -1)) {
                    if (c < d) {
                        return -1;
                    } else if (c > d) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (!order || (order && order === 1)) {
                    if (c > d) {
                        return -1;
                    } else if (c < d) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
        return await arr.sort((c, d) => {
            if (typeof c === 'string' && typeof d === 'string') {
                c = c.toLowerCase();
                d = d.toLowerCase();
            }
            if (c < d) {
                return -1;
            } else if (c > d) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    getProperty(object: any, propertyName: any): any {
        let parts: any[]; let property: string;
        parts = propertyName.split('.'); const length = parts.length;
        let i: number;
        property = object;
        for (i = 0; i < length; i++) {
          // @ts-ignore
          property = property[parts[i]];
        }
        return property;
      }


    customPagination(arr: any, key: string, direction: string, skip: any, limit: any): any {
        const limits = limit + skip;
        return arr.sort((a: any, b: any) => {
          const c: any = this.getProperty(a, key); const d: any = this.getProperty(b, key);
          return (direction === 'asc') ? c - d : d - c;
        }).filter((e: any, i: any) => i >= skip && i < limits);
      }

      reLoadPage (data):void{
        this.reload.next(data)
      }
}

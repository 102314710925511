<div class="flex flex-col flex-auto pt-4 mx-4 mt-[20px]">
    <mat-form-field class="mini-form-field fuse-mat-no-subscript fuse-mat-rounded mb-4 checkcompanyfield w-[11rem]">
        <mat-select
            class="mat-small w-full round-select"
            placeholder=""
            (openedChange)="openChange($event)"
            (selectionChange)="selectionChange($event)"
            value="Nellore">
            <mat-option>
                <ngx-mat-select-search
                [formControl]="companyFilterCtrl"
                placeholderLabel="Choose Location"
                [noEntriesFoundLabel]="'No matching company found'"
                [searching]="searching"
                [enableClearOnEscapePressed]="true">
                </ngx-mat-select-search>
            </mat-option>

            <mat-option matTooltip='Nellore' matTooltipPosition="left" value="Nellore">
                Nellore
            </mat-option>
        </mat-select>

        <!-- <mat-option></mat-option> -->

    </mat-form-field>
</div>

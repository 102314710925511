export const enum_data = {
    INITIATED: "Initiated",
    STARTED: "Started",
    INPROGRESS: "InProgress",
    SUCCESS: "Success",
    COMPLETED: "Completed",
    FAILED: "Failed",
    Open: "Open",
    Approved: "Approved",
    Rejected: "Rejected",
    every_day: "Every Day",
    weekly: "Weekly",
    days_in_a_month: "Days In A Month",
    hourly: "Hourly"
}
